<template>
    <v-container>
        <vue-draggable-resizable :h="600" :w="350">
            <BT-Blade title="Menu" :canRefresh="false" hideBackButton>
                <template>
                    <v-expansion-panels>

                        <BT-Blade-Expansion-Items
                            :canExportCSV="false"
                            :dividers="false"
                            :headers="[{ text: 'Name', value: 'groupName', title: 1 }]"
                            :itemProperties="['ID','GroupName']"
                            navigation="automatic-line-item-groups"
                            :onSelect="(b, item) => { openAutoLineBox(item) }"
                            showList
                            title="Automatic Lines" />

                        <BT-Blade-Expansion-Items
                            :canExportCSV="false"
                            :dividers="false"
                            :headers="[{ text: 'Name', value: 'groupName', title: 1 }]"
                            :itemProperties="['ID','GroupName']"
                            navigation="ordering-rules-groups"
                            :onSelect="(b, item) => { openOrderRulesGroupBox(item) }"
                            showList
                            title="Order Rules" />
<!-- 
                        <BT-Blade-Expansion-Items
                            :canExportCSV="false"
                            :dividers="false"
                            :headers="[{ text: 'Name', value: 'groupName', title: 1 }]"
                            :itemProperties="['ID','GroupName']"
                            navigation="order-slot-groups"
                            :onSelect="(b, item) => { openOrderSlotGroupBox(item) }"
                            showList
                            title="Order Slot Groups" /> -->

                        <BT-Blade-Expansion-Items
                            :canExportCSV="false"
                            :dividers="false"
                            :headers="[{ text: 'Name', value: 'slotName', title: 1 }]"
                            :itemProperties="['ID','SlotName']"
                            navigation="order-slots"
                            :onSelect="(b, item) => { openOrderSlotBox(item) }"
                            showList
                            title="Order Slots" />

                        <BT-Blade-Expansion-Items
                            :canExportCSV="false"
                            :dividers="false"
                            :headers="[{ text: 'Name', value: 'paymentTermsName', title: 1 }]"
                            :itemProperties="['ID','PaymentTermsName']"
                            navigation="supplier-payment-terms"
                            :onSelect="(b, item) => { openPaymentTermsBox(item) }"
                            showList
                            title="Payment Terms" />

                        <BT-Blade-Expansion-Items
                            :canExportCSV="false"
                            :dividers="false"
                            :headers="[{ text: 'Name', value: 'priceTierName', title: 1 }]"
                            :itemProperties="['ID','PriceTierName']"
                            navigation="supplier-price-tiers"
                            :onSelect="(b, item) => { openPriceTierBox(item) }"
                            showList
                            title="Price Tiers" />

                        <BT-Blade-Expansion-Items
                            :canExportCSV="false"
                            :dividers="false"
                            :headers="[{ text: 'Name', value: 'groupName', title: 1 }]"
                            :itemProperties="['ID','GroupName']"
                            navigation="product-groups"
                            :onSelect="(b, item) => { openProductOfferingGroupBox(item) }"
                            showList
                            title="Product Groups" />

                        <BT-Blade-Expansion-Items
                            :canExportCSV="false"
                            :dividers="false"
                            :headers="[{ text: 'Name', value: 'templateName', title: 1 }]"
                            :itemProperties="['ID','TemplateName']"
                            navigation="customer-templates"
                            :onSelect="(b, item) => { openTemplateBox(item) }"
                            showList
                            title="Customer Templates" />

                    </v-expansion-panels>
                </template>
            </BT-Blade>
        </vue-draggable-resizable>

        <BT-Board-Drop-List
            v-for="(block, index) in blocks"
            @close="closeDropBox"
            @removeLink="removeLink"
            @select="selectBox"
            :key="'d' + index"
            :item="block.item"
            :itemProperties="block.itemProperties"
            :linkNavigation="block.linkNavigation"
            :linkGetURL="block.linkGetURL"
            :linkParams="block.linkParams"
            :linkRemoveByDelete="block.linkRemoveByDelete"
            :manyNavigation="block.manyNavigation"
            :manyProp="block.manyProp"
            :manyText="block.manyText"
            :manyToMany="block.manyToMany"
            :searchProps="block.searchProps"
            :singleID="block.singleID"
            :singleProp="block.singleProp"
            :sortProp="block.sortProp"
            :titleProp="block.titleProp" />

        <BT-Board-Supply-List
            v-for="(block, index) in supplyBlocks"
            @close="closeSupplyBox"
            :canInsert="block.canInsert"
            :key="'s' + index"
            :itemProperties="block.itemProperties"
            :linkNavigation="block.linkNavigation"
            :linkGetURL="block.linkGetURL"
            :linkParams="block.linkParams"
            :linkRemoveByDelete="block.linkRemoveByDelete"
            :linkRemoveToggle="block.linkRemoveToggle"
            :manyNavigation="block.manyNavigation"
            :manyProp="block.manyProp"
            :manyText="block.manyText"
            :manyToMany="block.manyToMany"
            :searchProps="block.searchProps"
            :singleID="block.singleID"
            :singleProp="block.singleProp"
            :sortProp="block.sortProp" />

    </v-container>
</template>

<script>
export default {
    name: 'Customer-Board',
    components: {
        BTBoardDropList: () => import('~components/BT-Board-Drop-List.vue'),
        BTBoardSupplyList: () => import('~components/BT-Board-Supply-List.vue'),
        VueDraggableResizable: () => import('vue-draggable-resizable')
    },
    data: function() {
        return {
            blocks: [],
            itemProperties: ['ID','Buyer','AutomaticLineItemGroupID','OrderSlotGroupID','OrderItemRulesGroupID','ProductOfferingGroupID','PriceTierID','PaymentTermsID','SupplyAgreementTemplateID'],
            supplyBlocks: [],
        }
    },
    methods: {
        closeDropBox({ id }) {
            var ind = this.blocks.findIndex(x => x.item.id == id);
            if (ind > -1) {
                var nav = this.blocks[ind].navigation;
                this.blocks.splice(ind, 1);

                if (!this.blocks.some(y => y.navigation == nav)) {
                    this.closeSupplyBox({ navigation: nav });
                }
            }
        },
        closeSupplyBox({ navigation }) {
            var ind = this.supplyBlocks.findIndex(x => x.manyNavigation == navigation);
            if (ind > -1) {
                this.supplyBlocks.splice(ind, 1);

                if (this.blocks.some(y => y.manyNavigation == navigation)) {
                    this.blocks = this.blocks.filter(x => x.manyNavigation != navigation);
                }
            }
        },

        openAutoLineBox(item) {
            this.openBox(
                item,
                this.itemProperties,
                null,
                null,
                'customers',
                null,
                'buyer.companyName',
                false,
                ['buyer.companyName'],
                'automaticLineItemGroupID',
                'buyer.companyName',
                'groupName')
        },
        openOrderRulesGroupBox(item) {
            this.openBox(
                item,
                this.itemProperties,
                null,
                null,
                'customers',
                null,
                'buyer.companyName',
                false,
                ['buyer.companyName'],
                'orderItemRulesGroupID',
                'buyer.companyName',
                'groupName')
        },
        openOrderSlotBox(item) {
            this.openBox(
                item,
                this.itemProperties,
                '/get/Links?orderSlotID={id}',
                'customers',
                'customers',
                'supplyAgreementID',
                'buyer.companyName',
                true,
                ['buyer.companyName'],
                'orderSlotID',
                'buyer.companyName',
                'slotName')
        },
        openOrderSlotGroupBox(item) {
            this.openBox(
                item,
                this.itemProperties,
                null,
                null,
                'customers',
                null,
                'buyer.companyName',
                false,
                ['buyer.companyName'],
                'orderSlotGroupID',
                'buyer.companyName',
                'groupName')
        },
        openPaymentTermsBox(item) {
            this.openBox(
                item,
                this.itemProperties,
                null,
                null,
                'customers',
                null,
                'buyer.companyName',
                false,
                ['buyer.companyName'],
                'paymentTermsID',
                'buyer.companyName',
                'paymentTermsName')
        },
        openPriceTierBox(item) {
            this.openBox(
                item,
                this.itemProperties,
                null,
                null,
                'customers',
                null,
                'buyer.companyName',
                false,
                ['buyer.companyName'],
                'priceTierID',
                'buyer.companyName',
                'priceTierName')
        },
        openProductOfferingGroupBox(item) {
            this.openBox(
                item,
                this.itemProperties,
                null,
                null,
                'customers',
                null,
                'buyer.companyName',
                false,
                ['buyer.companyName'],
                'productOfferingGroupID',
                'buyer.companyName',
                'groupName')
        },
        openTemplateBox(item) {
            this.openBox(
                item,
                this.itemProperties,
                null,
                null,
                'customers',
                null,
                'buyer.companyName',
                false,
                ['buyer.companyName'],
                'supplyAgreementTemplateID',
                'buyer.companyName',
                'templateName')
        },

        openBox(
            item,
            itemProperties = null,
            linkGetURL = null,
            linkNavigation = null,
            manyNavigation = 'customers',
            manyProp = null,
            manyText = null,
            manyToMany = null,
            searchProps = null,
            singleProp = null,
            sortProp = 'buyer.companyName',
            titleProp = 'groupName') {
            
            this.blocks = this.blocks.filter(x => x.singleProp == singleProp && x.manyNavigation == manyNavigation);
            
            if (!this.blocks.some(y => y.item.id == item.id)) {
                this.blocks.push({
                    item,
                    itemProperties,
                    linkGetURL,
                    linkNavigation,
                    manyNavigation,
                    manyProp,
                    manyText,
                    manyToMany,
                    searchProps,
                    singleProp,
                    sortProp,
                    titleProp
                });
            }

            var supplyBlock = this.supplyBlocks.find(y => y.manyNavigation == manyNavigation);
            if (supplyBlock == null) {
                this.supplyBlocks.push({
                    canInsert: true,
                    itemProperties,
                    linkGetURL,
                    linkNavigation,
                    linkRemoveToggle: null,
                    manyNavigation,
                    manyProp,
                    manyText,
                    manyToMany,
                    searchProps,
                    singleID: item.id,
                    singleProp,
                    sortProp,
                    title: 'Customers'
                })
            }
            else {
                supplyBlock.linkNavigation = linkNavigation;
                supplyBlock.linkGetURL = linkGetURL;
                supplyBlock.manyNavigation = manyNavigation;
                supplyBlock.manyProp = manyProp;
                supplyBlock.manyText = manyText;
                supplyBlock.manyToMany = manyToMany;
                supplyBlock.singleProp = singleProp;
                supplyBlock.singleID = item.id;
            }

            this.selectBox({
                linkNavigation,
                linkGetURL,
                manyNavigation,
                manyProp,
                manyText,
                manyToMany,
                singleID: item.id,
                singleProp
            });
        },

        removeLink({ manyNavigation, manyID, singleID }) {
            console.log('removing link');
            var sBox = this.supplyBlocks.find(y => y.manyNavigation == manyNavigation);
            if (sBox != null) {
                console.log('sbox found');
                sBox.linkRemoveToggle = { manyID, singleID };
            }
        },
        selectBox(d) { //manyNavigation, manyToMany, singleID, singleProp }) {
            var sBox = this.supplyBlocks.find(y => y.manyNavigation == d.manyNavigation);
            if (sBox != null) {
                console.log(d);
                sBox.linkNavigation = d.linkNavigation;
                sBox.linkGetURL = d.linkGetURL;
                sBox.manyNavigation = d.manyNavigation;
                sBox.manyProp = d.manyProp;
                sBox.manyText = d.manyText;
                sBox.manyToMany = d.manyToMany;
                sBox.singleProp = d.singleProp;
                sBox.singleID = d.singleID;
            }
        }
    }
}
</script>